import "../css/style/module/top.scss";
import SmoothScroll from 'smooth-scroll';
(function (window, document, $) {
    'use strict';
   
    //Swiper
    let secCaseSwiper = null;
    const mediaQuery = window.matchMedia('(max-width: 767px)');

    const checkBreakpoint = (e) => {
        if (e.matches) {
            initSwiper();
        } else if (secCaseSwiper) {
            secCaseSwiper.destroy(false, true);
        }
    }

    const initSwiper = () => {
        secCaseSwiper = new Swiper('.js-slider .swiper', {
            // slidesPerView: 'auto',
            // slidesPerView: 2,
            spaceBetween: 24,
            // centeredSlides: true,
            grabCursor: true,
            // loop: true,
            // loopAdditionalSlides: 1,
            // pagination: {
            //     el: '.js-slider .swiper-pagination',
            //     clickable: true,
            // },
            navigation: {
                nextEl: '.js-slider .swiper-button-next',
                prevEl: '.js-slider .swiper-button-prev',
            },

        });
    };

    mediaQuery.addEventListener('change', checkBreakpoint);
    checkBreakpoint(mediaQuery);

})(window, document, $);
